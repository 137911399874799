<template>
  <b-form>
    <b-card title="Ekle">
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="career.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Metin"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="career.content"
          placeholder="Metin"
        />
      </b-form-group>
      <b-form-group
        label="İlan Durumu"
        label-for="status"
      >
        <v-select
          id="status"
          v-model="career.status"
          placeholder="Durum"
          :options="statuses"
          label="title"
          :reduce="status => status.id"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    vSelect,
  },
  data() {
    return {
      submitStatus: false,
      statuses: [
        {
          id: 1,
          title: 'Açık',
        },
        {
          id: 0,
          title: 'Kapalı',
        },
      ],
    }
  },
  computed: {
    career() {
      return this.$store.getters['career/getCareer']
    },
    saveStatus() {
      return this.$store.getters['career/getCareerSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.$store.dispatch('career/careerReset')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.career.submitStatus = true
      this.$store.dispatch('career/careerSave', this.career)
    },
  },
}
</script>
